import { Menu } from '../models/menu.model';

export enum MenuItemsEnum {
	// Main entries
	HelpDeskComponent = 1000,
	AppDashboardComponent = 2000,

	// Help Desk entries
	TestCredentialsComponent,
	UserAppClientComponent = 1010,
	CreditDocumentNumbersComponent = 1020,
	PTMAssignedStoresComponent = 1030,
	ReordersComponent = 1040,
	ShopbackLocationsComponent = 1050,
	SeasonalStorageDashboardComponent = 1060,
	NegativeAdjustmentsDashboardComponent = 1070,
	PositiveAdjustmentsDashboardComponent = 1080,
	EmptyPocketsDashboardComponent = 1090,
	CallsAndHoursComponent = 1100,
	VoltResetComponent = 1110,
	SotiRemoveComponent = 1120,
    SotiHistoryComponent = 1130,
	PhysicalCountComponent = 1140,

	// App Dashboard entries
	AssetReportComponent = 2010,
	DeadLetterComponent = 2020,
	ApplicationsReport = 2030
}

export const menuItems = [
	new Menu(
		MenuItemsEnum.HelpDeskComponent,
		`HelpDesk`,
		'/help-desk',
		null,
		'record_voice_over',
		null,
		null,
		true,
		0
	),
	new Menu(
		MenuItemsEnum.TestCredentialsComponent,
		`Test Credentials`,
		'/help-desk/test-credentials',
		null,
		'account_box',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),	new Menu(
		MenuItemsEnum.UserAppClientComponent,
		`User Application Client`,
		'/help-desk/users',
		null,
		'account_box',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.CreditDocumentNumbersComponent,
		`Credit Document Numbers`,
		'/help-desk/credit-document-numbers',
		null,
		'file_copy',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.PTMAssignedStoresComponent,
		`PTM Assigned Stores`,
		'/help-desk/ptm-assigned-stores',
		null,
		'store',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.ReordersComponent,
		`Reorders`,
		'/help-desk/reorders',
		null,
		'border_all',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.ShopbackLocationsComponent,
		`Shopback Locations`,
		'/help-desk/shopback-locations',
		null,
		'shopping_basket',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.SeasonalStorageDashboardComponent,
		`Seasonal Storage`,
		'/help-desk/seasonal-storage',
		null,
		'border_horizontal',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.NegativeAdjustmentsDashboardComponent,
		`Negative Adjustments`,
		'/help-desk/negative-adjustments',
		null,
		'border_horizontal',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.PositiveAdjustmentsDashboardComponent,
		`Positive Adjustments`,
		'/help-desk/positive-adjustments',
		null,
		'border_inner',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.EmptyPocketsDashboardComponent,
		`Empty Pockets`,
		'/help-desk/empty-pockets',
		null,
		'border_clear',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.CallsAndHoursComponent,
		`Calls and Hours`,
		'/help-desk/calls-hours',
		null,
		'av_timer',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.VoltResetComponent,
		`Volt Reset`,
		'/help-desk/volt-reset',
		null,
		'rotate_left',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
	new Menu(
		MenuItemsEnum.SotiRemoveComponent,
		`SOTI Bulk Remove`,
		'/help-desk/soti-remove',
		null,
		'remove_circle',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),
    new Menu(
        MenuItemsEnum.SotiHistoryComponent,
        `SOTI Remove History`,
        '/help-desk/soti-remove-history',
        null,
        'history',
        null,
        null,
        false,
        MenuItemsEnum.HelpDeskComponent
    ),

	new Menu(
		MenuItemsEnum.PhysicalCountComponent,
		`Physical Count`,
		'/help-desk/physical-count',
		null,
		'format_list_numbered',
		null,
		null,
		false,
		MenuItemsEnum.HelpDeskComponent
	),

	new Menu(
		MenuItemsEnum.AppDashboardComponent,
		`App Dashboard`,
		'/app-dashboard',
		null,
		'assessment',
		null,
		null,
		true,
		0
	),
	new Menu(
		MenuItemsEnum.AssetReportComponent,
		`Asset Trail Report`,
		'/app-dashboard/asset-report',
		null,
		'devices_other',
		null,
		null,
		false,
		MenuItemsEnum.AppDashboardComponent
	),
	new Menu(
		MenuItemsEnum.AssetReportComponent,
		`Job Statistics`,
		'/app-dashboard/job-statistics',
		null,
		'settings',
		null,
		null,
		false,
		MenuItemsEnum.AppDashboardComponent
	),
	new Menu(
		MenuItemsEnum.AssetReportComponent,
		`Tablet Config`,
		'/app-dashboard/tablet-config',
		null,
		'tablet',
		null,
		null,
		false,
		MenuItemsEnum.AppDashboardComponent
	),
	new Menu(
		MenuItemsEnum.AssetReportComponent,
		`Sync Audit`,
		'/app-dashboard/sync-audit',
		null,
		'podcasts',
		null,
		null,
		false,
		MenuItemsEnum.AppDashboardComponent
	),
	new Menu(
		MenuItemsEnum.ApplicationsReport,
		`Applications Report`,
		'/app-dashboard/applications-report',
		null,
		'podcasts',
		null,
		null,
		false,
		MenuItemsEnum.AppDashboardComponent
	),
	new Menu(
		MenuItemsEnum.DeadLetterComponent,
		`Dead Letter Docs`,
		'/app-dashboard/dead-letter-bucket',
		null,
		'description',
		null,
		null,
		false,
		MenuItemsEnum.AppDashboardComponent
	)
];
